import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Header from '../../components/giftSelection/Header';
import images from '../../constants/images';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CustomNavigate from '../../utils/navigate';

const PersonalizedMessage = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const corpState = useAppSelector((state) => state.corpSliceState);

  const recipientId =
    useSearchParams()[0].get('recid') ||
    selectGiftState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });
  const [showEnvelope, setShowEnvelope] = useState(false);

  const [textBoxVisible, setTextBoxVisible] = useState(false);
  const [startTextboxAnimation, setStartTextboxAnimation] = useState(false);

  const dispatch = useAppDispatch();

  const [result, setResult] = useState<Result>();
  useEffect(() => {
    const envelopeTimer = setTimeout(() => {
      setShowEnvelope(true);

      const textBoxTimer = setTimeout(() => {
        setShowEnvelope(false);
        setTextBoxVisible(true);
        setStartTextboxAnimation(true);
      }, 2000); // reduced delay times

      return () => clearTimeout(textBoxTimer);
    }, 1000); // reduced delay time

    return () => {
      clearTimeout(envelopeTimer);
    };
  }, []);

  useEffect(() => {
    if (!selectGiftState.campaignRecipientData) navigate.to('/notFound');
    if (selectGiftState.campaignRecipientData)
      setResult(selectGiftState.campaignRecipientData.result);
  }, [dispatch, recipientId, selectGiftState.campaignRecipientData]);

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  const logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ''
      ? corpState.data.logo
      : undefined;
  const recipientName =
    result?.orderRecipientDetails?.firstName ??
    result?.campaign.recipientAddressingName;
  return (
    <>
      <div
        className="relative w-full min-h-screen"
        style={{ backgroundColor: 'white' }}
      >
        <div
          className={isDeactivated ? 'absolute top-0 left-0 w-full z-10' : ''}
        >
          <Header logo={logo} isPreview={isDeactivated} />
        </div>
        {showEnvelope && (
          <img
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fadeIn"
            src={images?.envelopeOpenGif}
            alt="Envelope"
          />
        )}
        {textBoxVisible && (
          <div
            className={classNames(
              'flex',
              'flex-col',
              'items-center',
              'justify-center',
              'min-h-screen', // ensure it takes at least the full height of the screen
              'px-4', // added padding on the x-axis for mobile
              'sm:px-8', // scale up padding for slightly larger screens
              'relative' // Make parent div relatively positioned
            )}
          >
            <div className="absolute top-[15%] w-full flex justify-center">
              {' '}
              {/* Adjust 'top' to move sparkles down */}
              <img
                className="animate-bounce w-48 h-48"
                src={images.sparkles}
                alt="Sparkles"
              />
            </div>
            <div
              className={classNames(
                'w-full',
                'max-w-sm',
                'bg-white',
                'rounded-3xl',
                'shadow-lg',
                'p-8', // Reduced padding for small screens
                'm-0', // Removed margin for small screens
                'border',
                'border-gray-200',
                'sm:p-4', // Increased padding for slightly larger screens
                'sm:m-2',
                'md:p-8',
                'lg:p-12',
                {
                  'animate-scale-up-center': startTextboxAnimation,
                }
              )}
            >
              <div className="text-left font-leagueSpartan text-lg font-light px-4 py-2">
                {' '}
                {/* Added padding for text */}
                {result?.campaign?.recipientMessageTitle && recipientName && (
                  <span className="">
                    {result?.campaign?.recipientMessageTitle || 'Hey'}{' '}
                    {recipientName},
                  </span>
                )}
                <span className="block py-1 break-words">
                  {result?.recipientMessage ||
                    result?.campaign?.recipientMessage}
                </span>
                <span className="block py-1 break-words">
                  {result?.campaign?.footerGreeting || 'Warm regards,'}
                </span>
                <span className="whitespace-pre-line">
                  {result?.campaign?.messageFromName}
                </span>
                <div className="flex justify-center items-center h-full mt-4">
                  {/* Added top margin for button */}
                  <button
                    className="rounded-full border border-primaryPalette-600 py-3 px-5 text-neutral-100 w-3/5 md:1/5 animate-pulse bg-orange shadow-x font-medium"
                    onClick={() =>
                      navigate.to('/gift-selection/choose-category')
                    }
                  >
                    Choose Gift
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalizedMessage;
