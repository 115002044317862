import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import AppRoutes from './Routes/AppRoutes';
import Loading from './components/common/Loading';
import ToastCustomized from './components/common/ToastCustomized';
import { useAppSelector } from './redux/hooks';

const queryClient = new QueryClient();

function App() {
  const globalState = useAppSelector((state) => state.globalSliceState);
  return (
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
      {globalState.isLoading && <Loading />}
      <ToastCustomized />
    </QueryClientProvider>
  );
}

export default App;
