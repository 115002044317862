import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

export function useGetQuery<TData = unknown, TError = AxiosError>(
  key: QueryKey,
  url: string,
  getOptions?: any,
  options?: Omit<UseQueryOptions<TData, TError, TData>, 'queryKey' | 'queryFn'>
) {
  return useQuery<TData, TError>({
    queryKey: key,
    queryFn: async (): Promise<TData> => {
      const response = await axios.get<TData>(url, getOptions);
      return response.data; // Ensure the resolved value is only `TData`
    },
    ...options,
  });
}
