import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { TopUpModal } from '../../components/Modal/TopUpModal';
import CancelModalCampaign from '../../components/campaign/CancelModalCampaign';
import FooterCampaign from '../../components/campaign/FooterCampaign';
import GiftCollection from '../../components/campaign/GiftCollection';
import RecipientSchedule from '../../components/campaign/RecipientSchedule';
import SaveDraftModalCampaign from '../../components/campaign/SaveDraftModalCampaign';
import ScheduleSend from '../../components/campaign/ScheduleSend';
import CampaignDetailsProgress from '../../components/campaign/progress/CampaignDetailsProgress';
import GreetingCardProgress from '../../components/campaign/progress/GreetingCardProgress';
import Payment from '../../components/campaign/progress/Payment';
import RecipientsProgress from '../../components/campaign/progress/RecipientsProgress';
import ReviewProgress from '../../components/campaign/progress/ReviewProgress';
import CampaignCreateProgress from '../../components/common/CampaignCreateProgress';
import Header from '../../components/dashboard/Header';
import Api from '../../constants/api';
import images from '../../constants/images';
import { useGetQuery } from '../../hooks/common';
import { setCampaignResponseData } from '../../redux/campaignSlice';
import { setCorpData, setCorpFee } from '../../redux/corpSlice';
import { setIsLoading } from '../../redux/globalSlice';
import { useAppSelector } from '../../redux/hooks';
import { SubscriptionType } from '../../utils/constant';
import CustomNavigate from '../../utils/navigate';

export interface Values {
  campaignTriggerTime: string;
  campaignStartDate: string;
  campaignEndDate: string;
  campaignEndDateNotRequired: boolean;
  campaignStartTime: string;
}

export type TCampaignValue = {
  campaignName: string;
  selectedAmount: number;
  otherAmount: string;
  campaignType: string;
  collectionList: GetAllOccasionWithCollectionTCollection[];
  productIds: number[];
  charityList: GetAllCharitiesData[];
  occasionList: GetAllOccasionWithCollectionTData[];
  charityToggle: boolean;
  campaignTriggerTime: eventTriggreBeforeTypes;
  campaignStartDate: string;
  campaignEndDate: string;
  campaignEndDateNotRequired: boolean;
  campaignStartTime: string;
  selectedOccasion: number;
  selectedTheme: number;
  selectedThemeName: string;
  recipientMessageTitle: string;
  messageMethod: messageMethodType;
  recipientMessage: string;
  recipientMessageFrom: string;
  recipientMessageFooter: string;
  selectedGreetingCard: 'SelectGroup' | 'Upload' | 'GenerateLinks';
  recipientAddressingName: string;
  isSelfPickupOnly: boolean;
  pickupAddress?: string;
  showVouchers: boolean;
  recipientCustomFieldTriggerTime: SubscriptionType;
  recipientCustomField: string;
  isRecurring: boolean;
  recipientStartDate: string;
  recipientStartTime: string;
  isTouchpointEnabled: boolean;
  isReferralEnabled: boolean;
};

export const checkFakeId = (id: number | undefined) => {
  if (!id) return id;
  if (String(id).length === 15 && String(id).startsWith('111111111'))
    return undefined;
  return id;
};

const CampaignCreate = () => {
  const navigate = new CustomNavigate(useNavigate());
  const globalState = useAppSelector((state) => state.globalSliceState);
  const corpId = globalState.authData?.corp?.id!;

  const dispatch = useDispatch();
  const corpState = useAppSelector((state) => state.corpSliceState);
  const corp = corpState.data;

  const balance = corp?.balance || 0;

  const campaignNameRef = useRef<HTMLInputElement>(null);
  const topDivRef = useRef<HTMLDivElement>(null);

  const [customFields, setCustomFields] = useState<TCustomField[]>([]);

  const [campaignValue, setCampaignValue] = useState<TCampaignValue>({
    campaignName: '',
    selectedAmount: 0,
    otherAmount: '',
    campaignType: 'Gift',
    collectionList: [],
    productIds: [],
    charityList: [],
    occasionList: [],
    charityToggle: false,
    campaignTriggerTime: 'on occasion day',
    campaignStartDate: '12:00',
    campaignEndDate: '',
    campaignEndDateNotRequired: false,
    campaignStartTime: '',
    selectedTheme: -1,
    selectedThemeName: '',
    selectedOccasion: -1,
    recipientMessageTitle: 'Dear',
    messageMethod: 'automate',
    recipientMessage: '',
    recipientMessageFrom: '',
    recipientMessageFooter: '',
    selectedGreetingCard: 'SelectGroup',
    recipientAddressingName: '',
    isSelfPickupOnly: false,
    pickupAddress: '',
    showVouchers: false,
    recipientCustomFieldTriggerTime: SubscriptionType.OneTime,
    recipientCustomField: '',
    isRecurring: false,
    recipientStartDate: '',
    recipientStartTime: '',
    isTouchpointEnabled: false,
    isReferralEnabled: false,
  });

  const [recipientList, setRecipientList] = useState<OrderRecipientPartial[]>(
    []
  );
  const [selectedRecipients, setSelectedRecipients] = useState<
    OrderRecipientPartial[]
  >([]);
  const [numberOfLinks, setnumberOfLinks] = useState<string>();
  const [progress, setProgress] = useState(1);
  const [shouldRenderComponents, setShouldRenderComponents] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedCard, setSlectedCard] = useState(1);
  const [cancelModalVisible, setCancelViewModalVisible] = useState(false);
  const [saveDraftModalVisible, setSaveDraftModalVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRecipientGroup, setSelectedRecipientGroup] = useState(-1);
  const [newRows, setNewRows] = useState<OrderRecipientPartial[]>([]);
  const [recipientFieldToId, setRecipientFieldToId] = useState<{
    [key: string]: number;
  }>({});
  const [selectedPreview, setSelectedPreview] = useState<'Desktop' | 'Mobile'>(
    'Desktop'
  );
  const [selectedGreetingCard, setSelectedGreetingCard] = useState<
    'SelectGroup' | 'Upload' | 'generateLinks'
  >('SelectGroup');

  const numberOfSelectedRecipient = selectedRecipients.length;
  const finalSelectedAmount = +campaignValue.otherAmount;
  const feePercentage = corpState.fee / 100;

  const fee = finalSelectedAmount * feePercentage;
  const amountWithoutFee = finalSelectedAmount - fee;
  const totalAmountWithoutFee = amountWithoutFee * numberOfSelectedRecipient;
  const totalFeeAmount = +(fee * numberOfSelectedRecipient).toFixed(2);
  const totalAmount = +(totalAmountWithoutFee + totalFeeAmount).toFixed(2);

  const insuffecientBalance = balance < totalAmount;

  const pageSize = 10;

  const [selectedMessageViaEmailCheckBox, setSelectedMessageViaEmailCheckBox] =
    useState(false);
  const [
    selectedMessageViaWhatsappCheckBox,
    setSelectedMessageViaWhatsappCheckBox,
  ] = useState(false);

  const [showTopUpModal, setShowTopUpModal] = useState(false);

  const giftingUrl = process.env.REACT_APP_GIFTING_URL;

  const getCorp = useCallback(
    (refresh: boolean = false) => {
      if (!corp || refresh) {
        dispatch(setIsLoading(true));
        Api.getOneCorp(corpId)
          .then((res) => {
            dispatch(setCorpData(res.data));
            dispatch(
              setCorpFee(res.data.customPlans[0].defaultPlan?.commission || 0)
            );
          })
          .finally(() => dispatch(setIsLoading(false)));
      }
    },
    [corp, corpId, dispatch]
  );
  const getOccasion = useCallback((_corpID: number) => {
    Api.getAllOccasionWithCollection(_corpID).then((res) => {
      setCampaignValue((prev) => ({ ...prev, occasionList: res.data }));

      if (res.data.length && res.data[0].occasionTags)
        setCampaignValue((prev) => ({
          ...prev,
          collectionList: res.data[0].occasionTags.map((i) => i.collection),
        }));
    });
  }, []);

  useEffect(() => {
    if (corp && corp.id) {
      getOccasion(corp.id);

      Api.getAllCustomFields(corp.id).then((res) => {
        const recipientFieldToId = res.data.reduce<{
          [key: string]: number;
        }>((map, obj) => {
          map[obj.field] = obj.id;
          return map;
        }, {});
        setRecipientFieldToId(recipientFieldToId);
      });
    }
  }, [corp]);
  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const res = await Api.getAllCustomFields(corpId);

        console.log({ data: res.data });
        setCustomFields(res.data);
      } catch (error) {
        console.error('Failed to fetch custom fields:', error);
      }
    };

    fetchCustomFields();
  }, [corpId]);

  useEffect(() => {
    getCorp();
  }, [getCorp]);

  useEffect(() => {
    Api.getAllCharity().then((res) => {
      res.data.forEach((i) => (i.checked = false));
      setCampaignValue((prev) => ({ ...prev, charityList: res.data }));
    });
  }, [giftingUrl, pageNumber, pageSize, selectedRecipientGroup]);

  const selectAllRecipients = (newState: boolean) => {
    // if (!newState) {
    //   axios
    //     .get(`${giftingUrl}recipient/group/-1`, {
    //       //-1 is used to reset to All Groups
    //       params: {
    //         pageNumber,
    //         pageSize,
    //         corpId,
    //       },
    //     })
    //     .then((res) => {
    //       setTotalPages(res?.data?.data?.totalPages ?? 0);
    //       setRecipientList((prevList) => {
    //         const allFake = newRows;
    //         return res?.data?.data?.results.concat(allFake) ?? allFake;
    //       });
    //     });
    //   return;
    // }
    // axios
    //   .get(`${giftingUrl}/recipient/group/${selectedRecipientGroup}`, {
    //     params: { corpId },
    //   })
    //   .then((resp) => {
    //     const allFake = newRows;
    //     let allSelected: OrderRecipientPartial[] =
    //       resp?.data?.data.concat(allFake) ?? allFake;
    //     setRecipientList(allSelected);
    //     setSelectedRecipients(allSelected);
    //   });
  };

  useEffect(() => {
    if (
      campaignValue?.occasionList.length &&
      campaignValue?.selectedOccasion !== -1 &&
      campaignValue?.occasionList[campaignValue?.selectedOccasion].occasionTags
    )
      setCampaignValue((prev) => ({
        ...prev,
        collectionList: campaignValue?.occasionList[
          campaignValue?.selectedOccasion
        ].occasionTags.map((i) => i.collection),
      }));

    return () => {};
  }, [campaignValue?.occasionList, campaignValue?.selectedOccasion]);

  const updateValues = (
    value: string | boolean | null | Date,
    key: keyof TCampaignValue
  ) => {
    setCampaignValue({ ...campaignValue, [key]: value });
  };

  const createCampaign = useCallback(
    async (isDraft: boolean = false) => {
      if (!corp) {
        toast.error('Corp not found!');
        return;
      }
      const check = await Api.getCampaignByFilter({
        name: campaignValue.campaignName,
        corpId: corp.id,
      });
      if (check.data) {
        dispatch(setIsLoading(false));
        setSaveDraftModalVisible(false);
        toast.error('Campaign name already exists!');
        campaignNameRef.current?.focus();
        return;
      }

      let recipients: OrderRecipientPartial[] | undefined = [];

      switch (campaignValue.selectedGreetingCard) {
        case 'GenerateLinks':
          recipients = [...Array(numberOfLinks ? +numberOfLinks : 0)].map(
            () => {
              return {
                name: campaignValue?.recipientAddressingName,
                email: 'NA',
                phoneNumber: 'NA',
                firstName: '',
                lastName: '',
                birthDate: moment().format('YYYY-MM-DD'),
                role: 'NA',
                department: 'NA',
                recipientMessage: '',
              };
            }
          );
          break;
        case 'SelectGroup':
          recipients = recipientList
            .filter((r) => r.checked)
            .map((recipient) => ({
              id: recipient.id,
              firstName: recipient.firstName,
              email: recipient.email,
              lastName: recipient.lastName,
              phoneNumber: recipient.phoneNumber,
              birthDate: recipient.birthDate,
              role: recipient.role,
              department: recipient.department,
              createdAt: recipient.createdAt,
              recipientMessage: recipient?.recipientMessage ?? '',
            }));
          break;
        default:
          recipients = recipientList
            .filter((r) => r.checked)
            .map((recipient) => ({
              firstName: recipient.firstName,
              email: recipient.email,
              lastName: recipient.lastName,
              phoneNumber: recipient.phoneNumber,
              birthDate: recipient.birthDate,
              role: recipient.role,
              department: recipient.department,
              createdAt: recipient.createdAt,
              recipientMessage: recipient?.recipientMessage ?? '',
            }));
          break;
      }

      // recipients = recipients.length
      //   ? recipients.map((r) => {
      //       r.birthDate = moment(r.birthDate).format("YYYY-MM-DD");
      //       r.triggerTimeStamp = getTriggerTimestamp(
      //         r.birthDate,
      //         campaignValue?.campaignTriggerTime,
      //         campaignValue?.campaignStartTime
      //       );

      //       return r;
      //     })
      //   : undefined;

      let charities = campaignValue?.charityList
        .filter((i) => i.checked)
        .map((i) => i.id);

      recipients = selectedRecipients.map((recipient) => ({
        id: checkFakeId(recipient.id),
        firstName: recipient.firstName,
        email: recipient.email,
        lastName: recipient.lastName,
        phoneNumber: recipient.phoneNumber,
        birthDate: moment(recipient.birthDate).format('YYYY-MM-DD'),
        role: recipient.role,
        department: recipient.department,
        createdAt: recipient.createdAt,
        recipientMessage: recipient?.recipientMessage ?? '',
        occasions:
          recipient.recipientCustomFieldValues
            ?.filter((field) => field.recipientCustomField.type === 'date')
            .map((field) => ({
              name: field.recipientCustomField.field,
              dateString: field.value,
            })) || [],
      }));

      const data: CreateCampaign = {
        name: campaignValue.campaignName,
        paymentMehtod: 'WALLET',
        isDraft,
        startDate: moment(
          campaignValue.campaignStartDate +
            ' ' +
            campaignValue.campaignStartTime
        ).toISOString(),
        endDate: !campaignValue.campaignEndDateNotRequired
          ? moment(campaignValue?.campaignEndDate).endOf('day').toISOString()
          : undefined,
        isSingle: !campaignValue?.campaignType
          ? undefined
          : campaignValue?.campaignType === 'Gift',
        triggerDate: campaignValue.campaignTriggerTime,
        perPersonBudget: finalSelectedAmount,
        campaignBalance: totalAmount,
        messageMethod: campaignValue.messageMethod,
        sendMessageViaEmail: selectedMessageViaEmailCheckBox,
        sendMessageViaWhatsapp: selectedMessageViaWhatsappCheckBox,
        recipientMessageTitle: campaignValue.recipientMessageTitle,
        recipientMessage: campaignValue.recipientMessage,
        messageFromName: campaignValue.recipientMessageFrom,
        footerGreeting: campaignValue.recipientMessageFooter,
        pickupAddress:
          campaignValue?.pickupAddress ||
          'Smilie team will reach out to you separately to arrange for pick up.',
        corpId: corp ? corp.id : undefined,
        occasionId:
          campaignValue?.occasionList.length &&
          campaignValue.selectedOccasion !== -1
            ? campaignValue?.occasionList[campaignValue.selectedOccasion].id
            : undefined,
        productIds: campaignValue.productIds,
        // collectionId:
        //   campaignValue?.collectionList.length && selectedProduct !== -1
        //     ? campaignValue?.collectionList[selectedProduct].id
        //     : undefined,
        recipients,
        charities,
        saveRecipients: campaignValue?.selectedGreetingCard !== 'GenerateLinks',
        recipientAddressingName: campaignValue?.recipientAddressingName,
        recipientsSelectMethod: campaignValue?.selectedGreetingCard,
        isSelfPickupOnly: campaignValue?.isSelfPickupOnly || false,
        showVouchers: campaignValue?.showVouchers || false,
        recipientCustomFieldId:
          recipientFieldToId[campaignValue.recipientCustomField],
        isRecurring: campaignValue?.isRecurring,
        recipientStartDate: campaignValue.recipientStartDate
          ? new Date(
              `${campaignValue.recipientStartDate} ${campaignValue.recipientStartTime}`
            )
          : null,
        // recipientStartTime: campaignValue?.recipientStartTime, // we are not supporting time yet
        isTouchpointEnabled: campaignValue?.isTouchpointEnabled,
        isReferralEnabled: campaignValue?.isReferralEnabled,
      };

      dispatch(setIsLoading(true));
      Api.createCampaign(data)
        .then((res) => {
          setIsLoading(false);
          dispatch(setCampaignResponseData(res.data));
          navigate.to('/dashboard/campaigns/create/success', undefined, {
            isDraft,
            campaignName: campaignValue.campaignName,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error('Error while creating campaign');
        })
        .finally(() => dispatch(setIsLoading(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      corp,
      recipientList,
      numberOfLinks,
      finalSelectedAmount,
      totalAmount,
      selectedMessageViaEmailCheckBox,
      selectedMessageViaWhatsappCheckBox,
      campaignValue,
      selectedProduct,
      dispatch,
    ]
  );
  const onClickNext = useCallback(async () => {
    if (progress === 1) {
      //  const filteredProducts = getFilteredProducts(
      //    campaignValue?.collectionList[selectedProduct],
      //    amountWithoutFee
      //  );
      if (campaignValue.campaignName === '') {
        toast.error('Enter campaign name!');
        campaignNameRef.current?.focus();
        return;

        // } else if (!filteredProducts.length) {
        //   toast.error("No products found for selected amount!");
        //   return;
        // } else if (campaignValue.productIds.length === 0) {
      } else if (campaignValue.productIds.length === 0) {
        toast.error('No products selected!');
        return;
      } else {
        if (!corp) {
          toast.error('Corp not found!');
          return;
        }
        const check = await Api.getCampaignByFilter({
          name: campaignValue.campaignName,
          corpId: corp.id,
        });
        if (check.data) {
          toast.error('Campaign name already exists!');
          campaignNameRef.current?.focus();
          return;
        }
      }
    }

    if (progress === 3) {
      if (
        campaignValue?.isSelfPickupOnly &&
        campaignValue?.pickupAddress === ''
      ) {
        toast.error('Enter Pick up address!');
        return;
      }

      const isOccasionSelected = campaignValue.recipientCustomField !== '';
      const isRecipientStartDateNotNull =
        campaignValue.recipientStartDate !== null;

      // if the user want to fire via email but never provide startDate or custom field
      if (
        !campaignValue.recipientStartDate &&
        !campaignValue.recipientCustomField &&
        selectedMessageViaEmailCheckBox
      ) {
        toast.error('Please select the option to send the message via email.');

        return;
      }
    }
    if (progress === 5) {
      if (insuffecientBalance) {
        toast.error('Insufficient Balance!');
        return;
      }
      createCampaign();
    }
    setProgress((p) => {
      const r = p + 1;
      if (r > 0 && r < 6) {
        topDivRef.current?.scrollIntoView();
        return r;
      }
      return p;
    });
  }, [
    progress,
    selectedProduct,
    amountWithoutFee,
    corp,
    campaignValue,
    insuffecientBalance,
    createCampaign,
    selectedMessageViaEmailCheckBox,
  ]);

  const onClickTopUpWallet = () => {
    setShowTopUpModal(true);
  };

  const handleCustomFieldOccasionUpdate = async (
    recipientList: OrderRecipientPartial[],
    recipientCustomFieldId: number
  ) => {
    try {
      const selectedRecipients = recipientList.filter((r) => r.checked);
      if (!selectedRecipients.length || recipientCustomFieldId == null) {
        return;
      }
      const result = await Api.checkValidRecipientField({
        recipientCustomFieldId,
        recipients: selectedRecipients,
      });
      if (result.success) {
        toast.success('All Recipients have valid occasion');
      } else {
        toast.error(result.message);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'An unexpected error occurred'
      );
    }
  };

  useEffect(() => {
    handleCustomFieldOccasionUpdate(
      recipientList,
      recipientFieldToId[campaignValue.recipientCustomField]
    );
  }, [recipientList, recipientFieldToId, campaignValue.recipientCustomField]);

  // code for recipient list

  const { data: recipientGroups, isLoading: loading } = useGetQuery<{
    data: GetAllRecipientGroupData[];
  }>(['recipientGroups', corpId], `${giftingUrl}recipient/allGroups/${corpId}`);

  const { data: groupRecipients, isLoading: groupLoading } = useGetQuery<any>(
    ['groupRecipients', selectedRecipientGroup, pageNumber, pageSize, corpId],
    `${giftingUrl}/recipient/group/${selectedRecipientGroup}`,
    {
      params: {
        corpId,
        pageNumber,
        pageSize,
      },
    }
  );

  const { data: groupRecipientsAllPage, isLoading: groupAllPAgeLoading } =
    useGetQuery<any>(
      ['groupRecipients', selectedRecipientGroup, corpId],
      `${giftingUrl}/recipient/group/${selectedRecipientGroup}`,
      {
        params: {
          corpId,
        },
      }
    );

  useEffect(() => {
    const arr = groupRecipients?.data?.results || [];
    const recipientGroupData =
      pageNumber === groupRecipients?.data?.totalPages
        ? arr.concat(newRows)
        : arr;
    setRecipientList(recipientGroupData);
  }, [groupRecipients, newRows]);

  const totalPages = groupRecipients?.data?.totalPages;

  return (
    <div className="relative min-h-screen">
      <div ref={topDivRef}></div>
      <Header logo={true} title=""></Header>
      <div className="p-4 mt-3 pb-[88px]">
        <CampaignCreateProgress progress={progress} setProgress={setProgress} />
        {progress === 1 && (
          <CampaignDetailsProgress
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setCampaignValue={setCampaignValue}
            campaignValue={campaignValue}
            campaignNameRef={campaignNameRef}
            amountWithoutFee={amountWithoutFee}
          />
        )}

        {progress === 2 && (
          <GreetingCardProgress
            selectedMessageViaEmailCheckBox={selectedMessageViaEmailCheckBox}
            selectedMessageViaWhatsappCheckBox={
              selectedMessageViaWhatsappCheckBox
            }
            setSelectedMessageViaEmailCheckBox={
              setSelectedMessageViaEmailCheckBox
            }
            setSelectedMessageViaWhatsappCheckBox={
              setSelectedMessageViaWhatsappCheckBox
            }
            selectedPreview={selectedPreview}
            setSelectedPreview={setSelectedPreview}
            value={campaignValue}
            setCampaignValue={setCampaignValue}
          />
        )}
        {progress === 3 && (
          <RecipientsProgress
            loading={groupLoading}
            setNewRows={setNewRows}
            customFields={customFields}
            setRecipientList={setRecipientList}
            setPaginationIndex={setPageNumber}
            setSelectedRecipientGroup={setSelectedRecipientGroup}
            selectedRecipients={selectedRecipients}
            setSelectedRecipients={setSelectedRecipients}
            recipientList={recipientList}
            recipientGroups={recipientGroups?.data || []}
            numberOfLinks={numberOfLinks}
            setnumberOfLinks={setnumberOfLinks}
            selectedRecipientGroup={selectedRecipientGroup}
            groupRecipientsAllPage={(groupRecipientsAllPage?.data || []).concat(
              newRows
            )}
            selectAllRecipients={selectAllRecipients}
            totalPages={totalPages}
            pageNumber={pageNumber}
            updateValues={updateValues}
            values={campaignValue}
            setCampaignValue={setCampaignValue}
            pageSize={pageSize}
            className="mt-20 mx-auto w-[85%] mb-4"
          >
            <GiftCollection
              values={campaignValue}
              updateValues={updateValues}
              className="mt-6"
            />
            <div
              className="self-center flex cursor-pointer mt-8"
              onClick={() => {
                setShouldRenderComponents((isRendered) => !isRendered);
                setSelectedMessageViaEmailCheckBox((isSelected) => !isSelected);
              }}
            >
              <img
                src={
                  shouldRenderComponents
                    ? images.checkBoxSelected
                    : images.checkBoxInactive
                }
                className="h-[20px] min-w-[20px]"
                alt=""
              />
              <div className="ml-2 font-leagueSpartan-400 text-[18px] text-neutrals-900">
                Send gift link through Smilie via Email
              </div>
            </div>
            {shouldRenderComponents && (
              <>
                <ScheduleSend
                  values={campaignValue}
                  updateValues={updateValues}
                  className="mt-4"
                  customFieldValueMap={recipientFieldToId}
                />
                {campaignValue.recipientCustomField.length === 0 && (
                  <RecipientSchedule
                    values={campaignValue}
                    updateValues={updateValues}
                    className="mt-4"
                  />
                )}
              </>
            )}
            <div
              className="self-center flex cursor-pointer mt-8"
              onClick={() => {
                setCampaignValue((prev) => ({
                  ...prev,
                  isTouchpointEnabled: !campaignValue.isTouchpointEnabled,
                }));
              }}
            >
              <img
                src={
                  campaignValue.isTouchpointEnabled
                    ? images.checkBoxSelected
                    : images.checkBoxInactive
                }
                className="h-[20px] min-w-[20px]"
                alt=""
              />
              <div className="ml-2 font-leagueSpartan-400 text-[18px] text-neutrals-900">
                Enable engagement
              </div>
            </div>
            <div
              className="self-center flex cursor-pointer mt-8"
              onClick={() => {
                setCampaignValue((prev) => ({
                  ...prev,
                  isReferralEnabled: !campaignValue.isReferralEnabled,
                }));
              }}
            >
              <img
                src={
                  campaignValue.isReferralEnabled
                    ? images.checkBoxSelected
                    : images.checkBoxInactive
                }
                className="h-[20px] min-w-[20px]"
                alt=""
              />
              <div className="ml-2 font-leagueSpartan-400 text-[18px] text-neutrals-900">
                Enable referral
              </div>
            </div>
            <p className="font-leagueSpartan-300 text-[18px] text-neutrals-800  py-8 mt-8">
              Note: You can pause or end this campaign anytime on the campaign
              page. Any remaining credits will be returned to your wallet.
            </p>
          </RecipientsProgress>
        )}
        {progress === 4 && (
          <ReviewProgress
            setProgress={setProgress}
            value={campaignValue}
            selectedProduct={selectedProduct}
            selectedMessageViaEmailCheckBox={selectedMessageViaEmailCheckBox}
            selectedMessageViaWhatsappCheckBox={
              selectedMessageViaWhatsappCheckBox
            }
            recipientGroups={recipientGroups?.data || []}
            selectedRecipientGroup={selectedRecipientGroup}
            numberOfSelectedRecipient={selectedRecipients.length}
            amountWithoutFee={amountWithoutFee}
            totalAmountWithoutFee={totalAmountWithoutFee}
            corpState={corpState}
            totalFeeAmount={totalFeeAmount}
            totalAmount={totalAmount}
          />
        )}
        {progress === 5 && (
          <Payment
            setSlectedCard={setSlectedCard}
            selectedCard={selectedCard}
            balance={balance}
            totalAmount={totalAmount}
            amountWithoutFee={amountWithoutFee}
            numberOfSelectedRecipient={numberOfSelectedRecipient}
            totalAmountWithoutFee={totalAmountWithoutFee}
            totalFeeAmount={totalFeeAmount}
            corpState={corpState}
            onclickTopUpWallet={onClickTopUpWallet}
          />
        )}
      </div>
      <FooterCampaign
        setCancelViewModalVisible={setCancelViewModalVisible}
        nextButtonDisable={
          campaignValue?.productIds.length === 0 || insuffecientBalance
        }
        progress={progress}
        setProgress={setProgress}
        setSaveDraftModalVisible={setSaveDraftModalVisible}
        topDivRef={topDivRef}
        onClickNext={onClickNext}
        collection={
          campaignValue?.selectedOccasion !== -1 &&
          campaignValue?.collectionList.length
            ? campaignValue?.collectionList[selectedProduct]
            : undefined
        }
      />
      {cancelModalVisible && (
        <CancelModalCampaign setModalVisible={setCancelViewModalVisible} />
      )}
      {saveDraftModalVisible && (
        <SaveDraftModalCampaign
          createCampaign={createCampaign}
          setModalVisible={setSaveDraftModalVisible}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {showTopUpModal && <TopUpModal setShowTopUpModal={setShowTopUpModal} />}
    </div>
  );
};

export default CampaignCreate;
